export default defineNuxtRouteMiddleware(() => {
    const authEndpoints = useAuthEndpoints();
    const { session } = authEndpoints.useAuth();
    const restaurants = session.value?.restaurants;

    if (!restaurants || restaurants?.length === 0) {
        return navigateTo('/select-tenant');
    }

    return true;
});
